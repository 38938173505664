<template>
  <ModalWrapper @close="closeAction" :isFoot="isFoot">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-20">
        <h1 class="title">Редактирование статуса</h1>
        <div v-if="formData" class="flex ai-c ggap-5">
          <BaseIcon
            class="ic-16"
            :class="formData.is_static ? 'grey' : 'primary'"
            :icon="formData.is_static ? 'lock' : 'unlock'"
          />
          {{ statusType }}
        </div>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="status-edit pos-r grid h-100 ai-fs">
        <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />

        <form v-if="formData" class="p-20 grid ggap-20">
          <section class="flex ai-c jc-sb ggap-10">
            <div class="flex ai-c ggap-10">
              <h4 class="title">Превью статуса:</h4>
              <small
                v-if="formData"
                class="marker"
                :style="{ color: formData.text_color, backgroundColor: formData.background_color }"
              >
                {{ formData.title || '-' }}
              </small>
            </div>
          </section>
          <section class="grid gtc-2 ggap-30">
            <FormInput
              class="w-100"
              label="Название"
              placeholder="Название"
              v-model="formData.title"
              @keydown.enter.prevent
              required
            />
            <div class="grid gtc-2">
              <FormColorPicker label="Цвет текста" v-model="formData.text_color" required />
              <FormColorPicker label="Цвет фона" v-model="formData.background_color" required />
            </div>
          </section>
        </form>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <template v-if="formData">
        <div v-if="isDelete" class="flex fd-c ai-c jc-с ggap-10 h-100 pt-10 pb-10">
          <h3 class="title">Вы действительно хотите удалить?</h3>

          <div class="flex ai-c ggap-20">
            <button class="btn grey" @click="isDelete = !isDelete">Нет, отмена</button>
            <button class="btn red" @click="deleteAction">
              <BaseIcon class="ic-16 white" icon="delete" />
              Да, удалить
            </button>
          </div>
        </div>
        <div v-else class="flex ai-c jc-sb ggap-20 h-100">
          <button class="btn grey" @click="isDeleteAction" :disabled="formData.is_static">
            <BaseIcon class="ic-16 red" icon="delete" />
            Удалить
          </button>

          <div class="flex ai-c ggap-20">
            <a v-if="isReset" href="#" class="alink t-red" @click.prevent="resetAction">Сбросить изменения</a>
            <button class="btn primary" @click="submitAction">
              <BaseIcon class="ic-16 white" icon="check-circle" />
              Сохранить изменения
            </button>
          </div>
        </div>
      </template>
    </template>
  </ModalWrapper>
</template>

<script setup>
import statuses from '@/api/modules/statuses'
import statusesDefault from '@/assets/json/statuses-default'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import {
  ModalWrapper,
  BaseLoad,
  BaseIcon,
  FormInput,
  FormColorPicker
  // FormSelect
} from '@/components'
import { $busEmit } from '@/plugins'

// Data
const store = useStore()
const route = useRoute()
const router = useRouter()
const isLoad = ref(false)
const isFoot = ref(true)
const isDelete = ref(false)
const formData = ref(null)

// Computed
const statusType = computed(() => (formData.value.type === 'system' ? 'Системный' : 'Кастомный'))
const isReset = computed(() => {
  let res = false
  const curItem = store.getters['helpers/statuses'].find((item) => Number(item.id) === Number(route.params.id))
  const resetCurItem = statusesDefault.find((item) => Number(item.id) === Number(route.params.id))
  if (curItem && resetCurItem) {
    res =
      resetCurItem.name !== curItem.name ||
      resetCurItem.text_color !== curItem.text_color ||
      resetCurItem.background_color !== curItem.background_color
  }
  return res
})

// Created
getItem()

// Methods
function getItem() {
  if (store.getters['helpers/statuses']?.length) {
    const res = store.getters['helpers/statuses'].find((item) => Number(item.id) === Number(route.params.id))
    formData.value = { ...res }
  } else {
    isLoad.value = true
    statuses
      .get(route.params.id)
      .then(({ data }) => {
        formData.value = { ...data.data }
      })
      .finally(() => (isLoad.value = false))
  }
}
function closeAction() {
  router.replace('/settings/statuses')
}
function submitAction() {
  isLoad.value = true
  isFoot.value = false
  const payload = { ...formData.value }
  delete payload.id
  delete payload.uid
  statuses
    .update(route.params.id, payload)
    .then(async () => {
      await store.dispatch('helpers/GET_STATUSES')
      isLoad.value = false
      closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Cтатус успешно изменен, вы сможете изменить его снова в любое время'
      })
    })
    .catch((e) => {
      isLoad.value = false
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, не удалось изменить статус.'
      })
    })
}
function isDeleteAction() {
  if (!formData.value?.is_static) isDelete.value = !isDelete.value
}
function deleteAction() {
  isLoad.value = true
  isFoot.value = false
  statuses
    .delete(formData.value.id)
    .then(async () => {
      await store.dispatch('helpers/GET_STATUSES')
      isLoad.value = false
      closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Статус успешно удален, востановить его нельзя.'
      })
    })
    .catch((e) => {
      isLoad.value = false
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, обратитесь к администратору, или попробуйте еще раз'
      })
    })
}
function resetAction() {
  isLoad.value = true
  isFoot.value = false

  const resetItem = statusesDefault.find((el) => el.id === formData.value.id)
  statuses
    .update(route.params.id, resetItem)
    .then(async () => {
      await store.dispatch('helpers/GET_STATUSES')
      isLoad.value = false
      closeAction()
      $busEmit('setToast', {
        type: 'blue',
        icon: 'check-circle',
        message: 'Cтатус успешно сброшен до состояния по умолчанию.'
      })
    })
    .catch((e) => {
      isLoad.value = false
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: e.response.status + ' ' + e.response.statusText,
        message: 'Что-то пошло не так, один или несколько статусов не удалось сбросить.'
      })
    })
}
</script>

<style lang="scss" scoped>
.status-edit {
  width: 600px;
  min-height: 160px;
}
</style>
